<template>
  <div class="container pt120">
    <div class="goods_box fs18 color9D">
      <div class="goods_item relative h744 mb160" v-for="(item,i) in solutionList" :key="i">
        <div :class="[i%2==0?'goods_img_even':'goods_img']">
          <img :src="item.pcPic" class="w744 h744" />
        </div>
        <div :class="['w720 ',i%2==0?'goods_content_even':'goods_content']">
          <div class="fs46 fw-600 color3">{{item.solutionList}}</div>
          <div class="mt42">
            {{item.solutionList}}
          </div>
          <div class="flex row-center">
            <div class="seeD mt42 text-center pointer" @click="$router.push(`/solutionD/${item.id}`)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'solutionGood',
  data () {
    return {
    };
  },
  computed: {
    ...mapState('solution', ['solutionList'])
  },
  components: {},

  created () { },

  mounted () { },

  methods: {
  }
}

</script>
<style lang='scss' scoped>
.goods_box {
  /* width: 1340px; */
  height: 100%;
  .goods_img {
    position: absolute;
    left: 0;
    top: 0;
  }
  .goods_img_even {
    position: absolute;
    right: 0;
    top: 0;
  }
  .goods_content {
    position: absolute;
    right: 0;
    top: 0;
  }
  .goods_content_even {
    position: absolute;
    left: 0;
    top: 0;
  }
  .seeD {
    width: 278px;
    height: 94px;
    background: #272727;
    border: 2px solid #272727;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 94px;
  }
}
</style>